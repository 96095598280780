<template>
  <TitleWrapper
    :filter-option="true"
    title="PICKUP_MEMBERS"
    tooltip-title="PICKUP_MEMBERS"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.first_name"
          :class="$style.widthItems"
          class="flex-1"
          title="First Name"
          type="text"
          placeholder="SEARCH_BY_FNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.FIRST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.last_name"
          :class="$style.widthItems"
          class="flex-1"
          title="Last Name"
          type="text"
          placeholder="SEARCH_BY_LNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.LAST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.phone"
          title="Phone Number"
          placeholder="Phone Number"
          class="flex-1"
          :filter="FILTERS_LIST.PHONE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.email"
          class="flex-1"
          title="Email"
          placeholder="Email"
          :filter="FILTERS_LIST.EMAIL"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_after"
          title="Date of birth greater than"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_GREATER_THAN"
          @change="birthDateAfter"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_before"
          title="Date of birth less than"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_LESS_THAN"
          @change="birthDateBefore"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.gender"
          title="Gender"
          :options="GENDERS"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.GENDER"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.login_enabled"
          title="STATUS"
          :options="LOGIN_ENABLED_STATUSES"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.LOGIN_ENABLED_STATUS"
          @emitQuery="updateQuery"
        />
        <UiMultiSelectBox
          v-model.trim="filtersPayload.student_ids"
          title="ASSOCAITED_STUDENTS"
          :options="studentList"
          reduce="associated_student_id"
          label="full_name"
          class="flex-1"
          :image="true"
          input-color="bg-white"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.STUDENTS"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import UiMultiSelectBox from '@src/components/UiElements/UiMultiSelect.vue'
import filterMixins from '@/src/mixins/filter-mixins.js'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import { USER_CONSTANTS } from '@src/constants/general-constants'
import { mapState, mapActions } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { getCountFromObject, objectDeepCopy } from '@utils/generalUtil'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiSingleSelect,
    TitleWrapper,
    UiDatePicker,
    UiMultiSelectBox,
    InputFieldWrapper,
    UiInput,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILTERS_LIST,
      studentList: [],
      $where: {},
      filtersCount: 0,
      forceRender: 0,
      GENDERS: USER_CONSTANTS.GENDERS,
      selectedStudents: [],
      LOGIN_ENABLED_STATUSES: USER_CONSTANTS.LOGIN_ENABLED_STATUSES,
      dashboard: 'dashboard',
      filtersPayload: {
        login_enabled: '',
        first_name: '',
        last_name: '',
        gender: '',
        student_ids: [],
        email: '',
        date_of_birth_before: '',
        date_of_birth_after: '',
        phone: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope || null,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
        this.getStudents()
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getStudents()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getStudents()
  },

  methods: {
    getFiltersCount() {
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    getStudents(query) {
      let alreadySelectedStudents = []
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = objectDeepCopy(res?.records)
        this.studentList?.forEach((record) => {
          record.associated_student_id = record.student_info?.id
          if (this.filtersPayload && this.filtersPayload.student_ids.length) {
            this.filtersPayload.student_ids.forEach((id) => {
              if (record.associated_student_id === id) alreadySelectedStudents.push(record)
            })
          }
          this.selectedStudents = alreadySelectedStudents
        })
      })
    },
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
