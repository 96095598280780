<template>
  <ValidationObserver @submit="assosiateStudent">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span class="text-text-color">Assosiate Student</span>
      </template>
      <template v-slot>
        <div>
          <div class="flex flex-col gap-5 pb-5 border-b border-primary-purple-100">
            <div class="flex font-roboto font-base gap-2 text-base">
              <div class="w-40 text-text-color">Pickup Person:</div>
              <div class="capitalize text-menu">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </div>
            </div>
            <div class="flex font-roboto font-base gap-2 text-base">
              <div class="w-40 text-text-color">Email:</div>
              <div class="capitalize text-menu">{{ currentUser.email }}</div>
            </div>
            <div class="flex font-roboto font-base gap-2 text-base">
              <div class="w-40 text-text-color">Contact:</div>
              <div class="capitalize text-menu">
                {{ currentUser.phone || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </div>
            </div>
          </div>
          <div class="my-6">
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="studentId"
                title="STUDENT"
                label="full_name"
                :image="true"
                class="flex-1"
                reduce="student_info"
                :options="studentlist"
                rules="required"
                :search-through-api="true"
                @search="getSectionStudents"
              />
              <InputBox
                v-model="relation"
                type="text"
                name="RELATION_WITH_GUARDIAN"
                title="RELATION_WITH_GUARDIAN"
                label="RELATION_WITH_GUARDIAN"
                placeholder="RELATION_WITH_GUARDIAN"
                class="flex-1"
                rules="required|alpha_spaces"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div v-if="currentStat > 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Save</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import FILTER_KEYS from '@src/constants/filter-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { buildWhereQuery } from '@utils/filters/index'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    InputBox,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      studentlist: [],
      studentId: '',
      relation: '',
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'isLoading']),
  },
  created() {
    this.getSectionStudents()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    getSectionStudents(query = '') {
      let studentCampuses = this.currentUser?.users_roles_entities?.map(
        (entity) => entity?.campus?.id,
      )
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
          ...buildWhereQuery(FILTER_KEYS.IN, 'campus_ids', studentCampuses),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentlist = res.records
      })
    },

    async assosiateStudent() {
      try {
        this.$store.commit('layout/IS_LOADING', true)

        let payload = {
          relation: this.relation,
          student_info_id: this.studentId?.id,
          pickup_person_info_id: this.currentUser?.pickup_person_info?.id,
        }
        await this.assosiateStudentsWithPickupMembers(payload).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: 'Assosiation Done',
          })
        })
        this.$emit('toggle', 'fetch')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('student', ['getStudentsWithFilters']),
    ...mapActions('pickup', ['assosiateStudentsWithPickupMembers']),
  },
}
</script>
