import PickupIndiviualsData from '@/src/router/views/pickup/pickup-person/PickupIndiviuals.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import classesData from '@/src/router/views/classes/classes.json'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (stat) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
    },
  ]
  return stats
}

export const PickupIndiviualsGraphChart = (stat) => {
  let PIE_GRAPH_VALUE = [
    stat?.total_pickup_persons - stat?.total_login_enabled,
    stat?.total_login_enabled,
  ]
  let BAR_GRAPH_VALUE = [
    stat?.total_pickup_persons,
    stat?.total_associated_pickup_persons,
    stat?.total_pickup_persons - stat?.total_associated_pickup_persons,
  ]
  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      PickupIndiviualsData.BAR_LABELS,
      BAR_GRAPH_VALUE,
      PickupIndiviualsData.BAR_TITLE,
      PickupIndiviualsData.BAR_LABELS_STRING,
    ),
    GRAPH_CONSTANTS.PIE_CHART(
      PickupIndiviualsData.PIE_LABELS,
      PIE_GRAPH_VALUE,
      PickupIndiviualsData.PIE_BACKGROUND_COLOR,
      PickupIndiviualsData.PIE_GRAPH_TITAL,
    ),
  ]
  return chartData
}
