<template>
  <ValidationObserver @submit="addEditPickupPerson">
    <UiModalContainer
      :modal-show="modal"
      :modal-width="50"
      footer-classes="flex justify-end gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">Edit Pickup Person</span>
        <span v-else>Add Pickup Person</span>
      </template>
      <template v-slot>
        <div class="checkInModal-body">
          <div
            v-if="currentStat < 1"
            class="mb-8 mx-2.5 flex justify-between flex-wrap flex-col lg:flex-row gap-2"
          >
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="flex items-center gap-2"
              :class="step.active ? 'active' : ''"
            >
              <div
                v-if="!step.markStatus"
                class="step-counter font-medium h-7 w-7 flex items-center justify-center text-menu border-menu border-2 rounded-full cursor-pointer"
              >
                <span>{{ index + 1 }}</span>
              </div>
              <span v-if="step.markStatus" class="iconBlock">
                <icon class="icon" icon="checkCircle" height="28" width="28" />
              </span>
              <span v-i18n="dashboard" class="step-info font-roboto font-medium text-menu text-sm">
                {{ step.info }}
              </span>
            </div>
          </div>
          <div v-if="currentStat === 1">
            <InputFieldWrapper>
              <UIInputBox
                v-model.trim="pickupPerson.pickup_person_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                label="First Name"
                placeholder="Enter First Name"
                rules="required|alpha_spaces"
                class="flex-1"
              />
              <UIInputBox
                v-model.trim="pickupPerson.pickup_person_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                placeholder="Enter Last Name"
                rules="required|alpha_spaces"
                class="flex-1"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                v-model="pickupPerson.pickup_person_info.gender"
                title="Gender"
                :options="genderArray"
                class="flex-1"
              />
              <UIInputBox
                id="img-btn"
                ref="imgUpload"
                v-model="pickupPersonImage"
                title="Photo"
                placeholder="Photo Upload"
                class="flex-1"
                type="file"
                :preview-image="previewImage"
                :rules="imageRules"
                @preview="preview"
                @clearValue="clearValue"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UIInputBox
                v-model="pickupPerson.pickup_person_info.email"
                type="email"
                name="Email"
                title="Email"
                label="Email"
                placeholder="Email"
                class="flex-1"
                :rules="
                  isPickupPersonActivated && !pickupPersonPhoneNumber && !edit
                    ? 'required|email'
                    : 'email'
                "
              />
              <PhoneNumber
                v-model="pickupPersonPhoneNumber"
                title="Phone Number"
                :rules="
                  isPickupPersonActivated && !pickupPerson.pickup_person_info.email && !edit
                    ? 'required|validNumber'
                    : 'validNumber'
                "
                class="flex-1"
                :disabled="!!reset"
                @update="updatePickupPersonNumber"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDatePicker
                v-model="pickupPerson.profile_info.date_of_birth"
                title="DOB"
                class="flex-1"
              />
              <UIInputBox
                v-model.trim="pickupPerson.profile_info.religion"
                type="text"
                name="Religion"
                title="Religion"
                label="Religion"
                placeholder="Religion"
                class="flex-1"
                rules="alpha_spaces"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="pickupPerson.profile_info.blood_group"
                title="Blood Group"
                :options="bloodGroupArray"
                class="flex-1"
              />
              <UIInputBox
                v-model="pickupPerson.pickup_person_info.address"
                type="text"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="flex-col-reverse">
              <UiSingleSelect
                v-model="pickupPerson.identity_info.type"
                title="Identity Type"
                :options="identityTypes"
                reduce="value"
                class="flex-1"
                @change="resetIdentityInfo"
              />
              <UiSingleSelect
                v-if="!campusId && !edit"
                v-model="pickupPerson.pickup_person_info.campus"
                title="CAMPUS"
                :options="campuses"
                label="title"
                class="flex-1"
                reduce="id"
                :search-through-api="true"
                rules="required"
                @search="getCampus"
              />
            </InputFieldWrapper>
            <InputFieldWrapper v-if="pickupPerson.identity_info.type">
              <UIInputBox
                v-model="pickupPerson.identity_info.value"
                type="text"
                name="Identity Number"
                title="Identity Number"
                label="Identity Number"
                placeholder="Enter Identity No"
                class="flex-1"
                rules="required|numeric"
              />
              <UIInputBox
                v-model="pickupPerson.identity_info.country"
                type="text"
                name="Country"
                title="Country"
                label="Country"
                placeholder="Country"
                class="flex-1"
                rules="required|alpha"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="justify-end">
              <ToggleButton
                v-if="!edit"
                v-model="isPickupPersonActivated"
                class="w-full sm:w-[48%]"
                heading="Enable Login"
                :is-status-hide="true"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>

      <template v-if="currentStat < 3" v-slot:footer>
        <div v-if="currentStat !== 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else>
              <span v-if="edit">Update</span>
              <span v-else>Save</span>
            </div>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UIInputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import icon from '@components/icons/icon.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { setObjectToEmptyFromNull, removeNestedObjectEmptyKeys } from '@src/utils/generalUtil.js'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import { transformPickupMembersData } from '@/src/utils/settings/tenant-user.util'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UIInputBox,
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    Loader,
    PhoneNumber,
    UiDatePicker,
    icon,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
    reset: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      currentStat: 1,
      steps: [{ stat: 1, info: 'Personal Info', active: true, markStatus: false }],

      genderArray: ['male', 'female'],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      previewImage: null,
      pickupPersonImage: null,
      pickupPersonPhoneNumber: '',
      gardianPhoneNumber: '',
      isPickupPersonActivated: true,
      identityTypes: [
        { title: this.$t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
        { title: this.$t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
        { title: this.$t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
      ],
      pickupPerson: {
        pickup_person_info: {
          first_name: '',
          last_name: '',
          email: '',
          gender: '',
          user_type: 'pickupPerson',
          profession: '',
          phone: '',
          address: '',
          campus: '',
          login_activated_at: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          height: '',
          weight: '',
          image: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
      },
    }
  },
  computed: {
    ...mapState('layout', ['campuses']),
    ...mapState({
      isLoading: (state) => state?.layout?.isLoading || false,
      campusId: (state) => state?.layout?.currentCampusScope?.id || undefined,
      currentSectionScope: (state) => state?.layout?.currentSectionScope?.id || undefined,
    }),
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },
  created() {
    if (this.edit) this.setPickupMemberData()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.previewImage = null
          break
      }
    },
    resetIdentityInfo() {
      this.pickupPerson.identity_info.value = ''
      this.pickupPerson.identity_info.country = ''
    },

    /**
     * Set previous step inactive and mark its status true
     * Set next step active and mark its status false
     */
    nextStep() {
      if (this.currentStat < this.steps.length) {
        this.steps[this.currentStat - 1].active = false
        this.steps[this.currentStat - 1].markStatus = true
        this.currentStat += 1
        this.steps[this.currentStat - 1].markStatus = false
        this.steps[this.currentStat - 1].active = true
      }
    },
    /**
     * Go back to previous step
     * Mark all forward step inactive and mark their status false
     */

    prevStep() {
      if (this.currentStat > 1) {
        this.currentStat -= 1
      }
      if (this.currentStat > 0) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
        }
        this.steps[this.currentStat].active = false
        this.steps[this.currentStat].markStatus = false
        this.steps[this.currentStat - 1].markStatus = false
      }
    },

    addEditPickupPerson() {
      if (this.currentStat === 1) {
        if (this.edit) this.editExistingPickupPerson()
        else this.addNewPickupPerson()
      } else this.nextStep()
    },
    /**
     * Add new pickupPerson
     * Append pickupPerson data and its profile picture in form data
     * Send post request with form data
     */
    addNewPickupPerson() {
      this.$store.commit('layout/IS_LOADING', true)
      if (this.isPickupPersonActivated)
        this.pickupPerson.pickup_person_info.login_activated_at = currentUTCDateTimeWithZone()
      const data = new FormData()

      data.append('first_name', this.pickupPerson.pickup_person_info.first_name)
      data.append('last_name', this.pickupPerson.pickup_person_info.last_name)

      data.append('gender', this.pickupPerson.pickup_person_info.gender)
      data.append('email', this.pickupPerson.pickup_person_info.email)
      data.append('user_type', 'pickupPerson')
      data.append('profession', this.pickupPerson.pickup_person_info.profession)
      data.append('address', this.pickupPerson.pickup_person_info.address)
      data.append('login_activated_at', this.pickupPerson.pickup_person_info.login_activated_at)
      data.append('blood_group', this.pickupPerson.profile_info.blood_group)
      data.append('date_of_birth', this.pickupPerson.profile_info.date_of_birth)
      data.append('religion', this.pickupPerson.profile_info.religion)
      data.append('nationality', this.pickupPerson.profile_info.nationality)
      data.append('height', this.pickupPerson.profile_info.height)
      data.append('weight', this.pickupPerson.profile_info.weight)
      data.append('type', this.pickupPerson.identity_info.type)
      data.append('value', this.pickupPerson.identity_info.value)
      data.append('country', this.pickupPerson.identity_info.country)
      data.append('phone', this.pickupPerson.pickup_person_info.phone)
      if (!this.campusId) data.append('campus_id', this.pickupPerson.pickup_person_info.campus)

      if (this.pickupPersonImage) data.append('profile_image', this.pickupPersonImage)
      this.createPickupMember(data)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    editExistingPickupPerson() {
      const form = new FormData()
      this.pickupPerson.pickup_person_info.login_activated_at = this.isPickupPersonActivated
        ? currentUTCDateTimeWithZone()
        : ''
      if (this.pickupPersonImage) {
        form.append('profile_image', this.pickupPersonImage)
      }
      form.append('first_name', this.pickupPerson.pickup_person_info.first_name)
      form.append('last_name', this.pickupPerson.pickup_person_info.last_name)
      form.append('gender', this.pickupPerson.pickup_person_info.gender)
      form.append('email', this.pickupPerson.pickup_person_info.email)
      form.append('user_type', 'pickupPerson')
      form.append('phone', this.pickupPerson.pickup_person_info.phone)
      form.append('profession', this.pickupPerson.pickup_person_info.profession)
      form.append('address', this.pickupPerson.pickup_person_info.address)
      form.append('login_activated_at', this.pickupPerson.pickup_person_info.login_activated_at)
      form.append('blood_group', this.pickupPerson.profile_info.blood_group)
      form.append('date_of_birth', this.pickupPerson.profile_info.date_of_birth)
      form.append('religion', this.pickupPerson.profile_info.religion)
      form.append('nationality', this.pickupPerson.profile_info.nationality)
      form.append('height', this.pickupPerson.profile_info.height)
      form.append('weight', this.pickupPerson.profile_info.weight)
      form.append('type', this.pickupPerson.identity_info.type)
      form.append('value', this.pickupPerson.identity_info.value)
      form.append('country', this.pickupPerson.identity_info.country)
      const data = {
        pickup: form,
        id: this.edit?.id,
      }
      this.editPickupMember(data)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    /**
     * Set selecte image to preview
     * @param {Object} image- selected image
     */
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.pickupPersonImage = image
    },

    /**
     * Mobile phone handle
     */
    updatePickupPersonNumber(payload) {
      this.pickupPerson.pickup_person_info.phone = payload.formattedNumber
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },

    setPickupMemberData() {
      this.pickupPerson = transformPickupMembersData(this.edit)
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.pickupPersonPhoneNumber = this.edit?.phone
      this.isPickupPersonActivated = !!this.edit?.login_activated_at
      this.pickupPerson.pickup_person_info.phone = this.pickupPersonPhoneNumber
    },
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('pickup', ['createPickupMember', 'editPickupMember']),
  },
}
</script>
