<template>
  <div>
    <PickupPersonFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />

    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(pickupIndiviualsList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipforFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            Campus
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')">
            <span v-i18n="dashboard">Actions</span>
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(user, index) in pickupIndiviualsList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <SingleUserDisplay
              :user="user"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="
                dropdownTopBottom(index, pickupIndiviualsList, 'bottom-6', 'top-6')
              "
              :tooltip="
                dropdownTopBottom(index, pickupIndiviualsList, 'tooltip-bottom', 'tooltip-top')
              "
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone', ['Phone'])">
            <DisplayPhoneNumber :phone="user.phone" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ user.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
            {{ user.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
            {{ (user.profile && user.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
            {{ (user.profile && user.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{ (user.profile && user.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
            {{ (user.profile && user.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD
            v-show="
              showColumnInDataTable(filtersData, 'Associated Students', ['Associated Students'])
            "
          >
            <MultiUserDisplay
              :options="user.students"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="
                dropdownTopBottom(index, pickupIndiviualsList, 'bottom-6', 'top-6')
              "
              :tooltip="
                dropdownTopBottom(index, pickupIndiviualsList, 'tooltip-bottom', 'tooltip-top')
              "
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="
                user.login_activated_at
                  ? GENERAL_CONSTANTS.LOGIN_ENABLED
                  : GENERAL_CONSTANTS.LOGIN_DISABLED
              "
              :success="!!user.login_activated_at"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            <span>
              <BadgeDisplay
                :options="user.users_roles_entities"
                :nested-label="['campus', 'title']"
                :not-show-initials="true"
                :is-object-concated="false"
              />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="user"
              :idx="index"
              :top="dropdownTopBottom(index, pickupIndiviualsList, -140, -12)"
              :drop-down="
                dropdownTopBottom(
                  index,
                  pickupIndiviualsList,
                  'tableDropDownbottom',
                  'tableDropDowntop',
                )
              "
              :right="50"
              :rtl-right="-245"
              :action-list="user.actionsList"
              @action="userActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(pickupIndiviualsCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />
    <AddPickupPersonModal
      v-if="showAddPickupPersonModal"
      :modal="showAddPickupPersonModal"
      :edit="currentUser"
      @toggle="togglePickupPersonModal"
    />
    <AssosiationModal
      v-if="assosiationModal"
      :modal="assosiationModal"
      :current-user="currentUser"
      @toggle="toggleAssosiationtModal"
    />
    <UserEnableModal @success="filterRecords" />
    <UserDeactivateModal @success="filterRecords" />
    <!-- Disable Modal -->
    <UserDisableModal @success="filterRecords" />
    <RoleModal
      v-if="isRoleModal"
      :modal="isRoleModal"
      user-type="Pickup Member"
      assigning-role="pickup_person"
      @toggle="toggleRoleModal"
    />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentUser?.full_name"
      remaining-message="?"
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
    <ResetPasswordModal @success="filterRecords" />
  </div>
</template>

<script>
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import AddPickupPersonModal from '@views/admin/modals/AddPickupPerson.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import AssosiationModal from '@src/router/views/pickup/pickup-person/modals/StudentAssosiationModal.vue'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import { getOptionsBasedOnUserStatus } from '@src/utils/settings/tenant-user.util.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import fileMixins from '@/src/mixins/file-mixins'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { dropdownTopBottom } from '@/src/utils/generalUtil.js'
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import PickupPersonFilter from '@src/router/views/pickup/pickup-person/PickupPersonFilter.vue'
import PickupPersonData from '@src/router/views/pickup/pickup-person/pickup-person.json'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  PickupIndiviualsGraphChart,
  rightBarStats,
} from '@src/router/views/pickup/pickup-person/PickupIndiviuals.ulit.js'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UserDisableModal,
    TableAction,
    Pagination,
    UIConfirmationModal,
    Loader,
    PickupPersonFilter,
    AddPickupPersonModal,
    MultiUserDisplay,
    SingleUserDisplay,
    BadgeDisplay,
    AssosiationModal,
    RoleModal,
    DisplayPhoneNumber,
    ResetPasswordModal,
    UserDeactivateModal,
    UserEnableModal,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      isLoading: false,
      actionList: null,
      currentUser: null,
      isSetupPassword: false,
      tableHeaders: PickupPersonData.tableHeaders,
      defaultSelectedColumns: PickupPersonData.defaultSelectedColumns,
      skipforFilterColumns: PickupPersonData.skipforFilterColumns,
      showPagination: false,
      pickupIndiviualsList: [],
      showAddPickupPersonModal: false,
      pickupIndiviualsCounts: 0,
      forceRender: 0,
      filtersData: {},
      assosiationModal: false,
      isRoleModal: false,
    }
  },

  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      showModal: (state) => state.layout.showModal,
      showUiModal: (state) => state.layout.showUiModal,
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      tabRightBar: (state) => state.layout.tabRightNav,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
  },
  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentUser = null
      },
    },
    currentCampusScope: {
      immediate: true,
      handler(value) {
        if (value) this.filterRecords()
      },
    },
    showModal: {
      handler() {
        this.togglePickupPersonModal()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchPickupMemberStats()
      },
    },
    showUiModal: {
      handler(value) {
        if (value) this.toggleRoleModal()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecords()
      },
    },
  },

  created() {
    this.filterRecords()
    this.setRightbar()
  },
  methods: {
    dropdownTopBottom,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) {
        this.$store.dispatch('layout/setShowUiModal', false)
        this.currentUser = null
      }
      if (payload) this.filterRecords()
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentUser = null
    },
    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentUser?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    userActions(action, currentStudent, idx) {
      this.currentUser = currentStudent
      this.currentUserPosition = idx
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentUser)
      switch (action) {
        case 'Edit':
          this.togglePickupPersonModal()
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
        case 'Assosiate Student':
          this.toggleAssosiationtModal()
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },

    tableActionList(userStatus) {
      this.actionList = [
        { name: 'Edit' },
        { name: 'TV_DEACTIVATE_USER' },
        { name: 'Assosiate Student' },
      ]

      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      this.actionList = [...this.actionList, ...options]

      return this.actionList
    },

    toggleAssosiationtModal(payload) {
      this.assosiationModal = !this.assosiationModal
      if (!this.assosiationModal) this.currentUser = null
      if (payload) this.filterRecords()
    },
    togglePickupPersonModal(payload) {
      this.showAddPickupPersonModal = !this.showAddPickupPersonModal
      if (!this.showAddPickupPersonModal) this.currentUser = null
      if (payload) this.filterRecords()
    },
    fetchPickupMemberStats(payload = { $where: {} }) {
      this.getPickupPersonStats(payload).then((res) => {
        this.setRightbar(res)
      })
    },
    filterRecords(range) {
      this.isLoading = true
      let payload = paginationRangeHandler(range)
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getPickupMembers(payload)
        .then((response) => {
          this.pickupIndiviualsList = response?.records
          this.pickupIndiviualsCounts = response?.meta?.total_records
          this.pickupIndiviualsList?.forEach((user) => {
            user.actionsList = this.tableActionList(user.login_activated_at)
            fullName(user)
            user?.students?.forEach((student) => {
              fullName(student)
            })
          })

          this.showPagination = this.pickupIndiviualsCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          this.fetchPickupMemberStats({ $where: this.filtersData?.$where })
          if (!range) this.forceRender++
        })
        .finally(() => {
          this.isLoading = false
          this.currentUser = null
        })
    },
    setRightbar(stats = null) {
      const rightBarContent = {
        header: {
          title: 'PICKUP_MEMBERS',
          buttons: [
            {
              title: 'ADD_STUDENT_PICKUP_PERSONS',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
            {
              title: 'ADD_EXISTING_USER',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal' },
            },
          ],
        },
        stats: rightBarStats(stats),
        chartData: PickupIndiviualsGraphChart(stats),
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('pickup', ['getPickupMembers', 'getPickupPersonStats']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
